import React from 'react'
import { Skeleton, Icon } from 'antd'
import CountUp from 'react-countup'
import { CSSTransition } from 'react-transition-group'
// import FlipNumbers from 'react-flip-numbers'
// import AnimatedNumber from 'react-animated-number'
import TimeAgo from 'react-timeago'

const renderRegion = (lastClickData) => {
  let region = ''
  if (
    lastClickData.regionName &&
    lastClickData.regionName.locality &&
    lastClickData.regionName.country
  ) {
    region = `${lastClickData.regionName.locality}, ${lastClickData.regionName.country}`
  } else if (lastClickData.regionName && lastClickData.regionName.locality) {
    region = `${lastClickData.regionName.locality}`
  } else if (lastClickData.regionName && lastClickData.regionName.country) {
    region = `${lastClickData.regionName.country}`
  } else {
    region = 'Somewhere on Earth'
  }
  return <h4 className="heading-uppercase">{region}</h4>
}

const LastCickedInfo = ({
  isTransitioning,
  isFetchingClicks,
  lastClickData,
  clicks,
}) => {
  // const [hasEnded, setEnd] = useState
  if (isFetchingClicks) {
    return (
      <div className="last-clicked-info-wrapper">
        <div className="last-clicked-info">
          <Skeleton active={true}></Skeleton>
        </div>
      </div>
    )
  } else if (lastClickData && clicks) {
    return (
      <div className="last-clicked-info-wrapper">
        <div className="last-clicked-info">
          <CSSTransition
            in={!isTransitioning}
            timeout={300}
            classNames="new-clicker-details"
            unmountOnExit
          >
            <div className="last-clicked-info__details">
              <div className="last-clicked-info__img">
                {lastClickData.image ? (
                  <img src={`${lastClickData.image}`} alt="Uniter" />
                ) : (
                  <Icon type="user" />
                )}
              </div>
              <div>
                <h2>
                  {lastClickData.name ? lastClickData.name : 'Anonymous Uniter'}
                  &nbsp;clicked last
                </h2>
                {renderRegion(lastClickData)}
                <h4 className="heading-uppercase">
                  <TimeAgo date={lastClickData.date} />
                </h4>
              </div>
            </div>
          </CSSTransition>

          <div className="last-clicked-info__counter">
            <span className="last-clicked-info__quantity">
              {/* <AnimatedNumber
                component="text"
                value={clicks.length}
                style={{
                  transition: '0.8s ease-out',
                  fontSize: 48,
                  color: '#fff',
                  transitionProperty: 'background-color, color, opacity',
                }}
                frameStyle={perc =>
                  perc === 100 ? {} : { backgroundColor: 'transparent' }
                }
                duration={600}
                stepPrecision={0}
              /> */}
              <CountUp
                start={0}
                end={clicks.length}
                delay={0}
                duration={3}
                separator=","
              >
                {({ countUpRef }) => (
                  <div>
                    <span ref={countUpRef} />
                  </div>
                )}
              </CountUp>
            </span>
            {/* <FlipNumbers
              height={14}
              width={14}
              delay={1}
              color="white"
              background="transparent"
              play
              perspective={10}
              numbers={clicks.length.toString()}
            /> */}
            {/* <CountUp className="last-clicked-info__quantity" end={100} /> */}

            <h3 style={{ color: 'white' }}>
              People have clicked the Unity Button
            </h3>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div className="last-clicked-info-wrapper">
        <div className="last-clicked-info">
          <div className="last-clicked-info__details">
            <h3 style={{ color: 'white', marginBottom: 0 }}>No clicks yet</h3>
          </div>
        </div>
      </div>
    )
  }
}

export default LastCickedInfo
