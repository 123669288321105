import React from 'react'
import { CSSTransition } from 'react-transition-group'

const UnityButton = React.forwardRef((props, refs) => {
  return (
    <>
      <CSSTransition
        in={!props.isFetchingIP}
        timeout={300}
        classNames="new-clicker-details"
        unmountOnExit
      >
        <div className="ub__header" ref={refs[2]}>
          {props.hasBeenClicked ? (
            <>
              {/* <h1 className="color-white">
                Unity Button: in solidarity with COVID-19 victims
              </h1> */}
              <h3 id="ub__post-click-msg" ref={refs[0]} className="color-white">
                Nice Click!
              </h3>
            </>
          ) : (
            <>
              <h1 className="color-white">
                Unity Button: We're all in this together
              </h1>
              <div className="unity-button-wrapper" ref={refs[1]}>
                <button
                  className="unity-button"
                  id="unity-button"
                  onClick={props.handleButtonClick}
                  // removing this for now because it fires twice on mobile

                  // onTouchEnd={props.handleButtonClick}
                ></button>
                <span id="unity-button__background"></span>
                <div className="unity-button__ring"></div>
              </div>
              <p className="click-prompt click-prompt_active">
                Click that ^ button <br />
                <span>You can only click it once - make it count :)</span>{' '}
              </p>
            </>
          )}
        </div>
      </CSSTransition>
      {props.isFetchingIP && (
        <div className="ub__header">
          <div className="ub__spinner"></div>
        </div>
      )}
    </>
  )
})

export default UnityButton
