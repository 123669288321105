import React from 'react'
import { Link } from 'react-router-dom'

const Footer = ({ toggleSharePanel }) => {
  return (
    <>
      <nav className="footer">
        <ul className="footer__links">
          <li className="">
            <Link to="/about" className="">
              About
            </Link>
          </li>
          <li className="">
            <span tabIndex="0" onClick={toggleSharePanel} className="">
              Share
            </span>
          </li>
          {/* <li className="">
            <Link to="/donate" className="">
              Donate
            </Link>
          </li>
          <li className="">
            <Link to="/sponsor" className="">
              Sponsor
            </Link>
          </li> */}
        </ul>
      </nav>
    </>
  )
}

export default Footer
