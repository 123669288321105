import React, { Component } from 'react'
import { Form, Icon, Input, Upload, Button, message } from 'antd'
import api from '../services/api'
import withSizes from 'react-sizes'
import { CSSTransition } from 'react-transition-group'

class PostClickForm extends Component {
  constructor() {
    super()
    this.state = {
      clickDetails: {
        imageUrl: '',
        name: '',
      },
      fileList: [],
      isCTAVisible: false,
      isHeadingVisible: false,
      isUploading: false,
      currFormPhase: 'CONGRATS',
    }
  }

  handleSubmit = (e) => {
    console.log('sub')
    this.props.onSubmit()
    e.preventDefault()
    this.props.form.validateFields(async (err, values) => {
      let image
      if (!err) {
        image =
          this.state.clickDetails && this.state.clickDetails.imageUrl
            ? this.state.clickDetails.imageUrl
            : null
        values = { ...values, image }
        this.props.handleFormSubmit(values)
      }
    })
  }

  normFile = (e) => {
    console.log('Upload event:', e)
    if (Array.isArray(e)) {
      return e
    }
    return e && e.fileList
  }

  onPostImage = async (info) => {
    let updatedFile = [
      {
        uid: info.file.uid,
        name: info.file.name,
        status: 'uploading',
        url: '',
      },
    ]
    updatedFile[0].percent = 69
    updatedFile[0].status = 'uploading'

    this.setState({ isUploading: true, fileList: updatedFile })
    try {
      const result = await api.postImage(info)
      if (result.data && result.data.image) {
        updatedFile[0].status = 'done'
        updatedFile[0].percent = 100
        updatedFile[0].url = result.data.image
        updatedFile[0].thmbUrl = result.data.image
        updatedFile[0].response = {
          image: result.data.image,
          status: 'success',
        }

        this.setState((prevState) => ({
          fileList: updatedFile,
          isUploading: false,
          clickDetails: {
            ...prevState.clickDetails,
            imageUrl: result.data.image,
          },
        }))
      }
    } catch (err) {
      console.log('onPostImage error: ', err)
      message.error('Error uploading image. Please refresh and try again.', 10)
      updatedFile[0].status = 'error'
      updatedFile[0].percent = 0
      this.setState({
        isUploading: false,
        fileList: updatedFile,
      })
    }
  }

  handleImageUpload = (info) => {
    this.setState({ isUploading: true }, () => {
      let fileList = info.fileList

      console.log('filelsit' + fileList[0])
      // 1. read from response and show file link
      fileList = fileList.map((file) => {
        if (file.response) {
          // Component will show file.url as link
          file.url = file.response.image
        }
        return file
      })

      // 2. filter successfully uploaded files according to response from server
      fileList = fileList.filter((file) => {
        if (file.response) {
          this.setState({ isUploading: false })
          return file.response.image
        }
        return true
      })

      this.setState((prevState) => ({
        fileList,
        clickDetails: {
          ...prevState.clickDetails,
          imageUrl:
            fileList[0] && fileList[0].response && fileList[0].url
              ? fileList[0].url
              : '',
        },
      }))
    })
  }

  renderLocationInfo = () => {
    if (this.props.isGettingGeolocation) {
      return (
        <>
          <Icon type="loading" />
          &nbsp; Locating
        </>
      )
    } else if (this.props.isFinishedLocating) {
      return (
        <>
          <Icon type="check-circle" />
          &nbsp; Enable GPS
        </>
      )
    } else {
      return 'Enable GPS'
    }
  }

  handeAnimation = () => {
    setTimeout(() => {
      this.setState({ isHeadingVisible: true })
    }, 400)
    setTimeout(() => {
      this.setState({ isCTAVisible: true })
    }, 1500)
  }

  switchPhase = (params) => {
    console.log(params)
    this.setState({ currFormPhase: params.phase })
  }

  renderFormBody = (props) => {
    const { currFormPhase, isCTAVisible, isHeadingVisible } = this.state
    if (currFormPhase === 'CONGRATS') {
      this.handeAnimation()
      return (
        <div className="congrats-message">
          <CSSTransition
            in={isHeadingVisible}
            timeout={300}
            classNames="alert"
            unmountOnExit
          >
            <div>
              <h2>You clicked the Unity Button!</h2>
            </div>
          </CSSTransition>
          <CSSTransition
            in={isCTAVisible}
            timeout={300}
            classNames="alert"
            unmountOnExit
            // onEnter={() => setShowButton(false)}
            // onExited={() => setShowButton(true)}
          >
            <>
              <h3>Want to show up on the homepage?</h3>
              <div className="form-button-group">
                <Button
                  type="secondary"
                  size="large"
                  onClick={this.handleSubmit}
                  htmlType="submit"
                  className="click-submit-button"
                >
                  Skip
                </Button>
                <Button
                  type="primary"
                  onClick={() => this.switchPhase({ phase: 'DETAILS' })}
                  size="large"
                  htmlType="submit"
                  className="click-submit-button"
                >
                  Add my details
                </Button>
              </div>
            </>
          </CSSTransition>
        </div>
      )
    } else {
      const { getFieldDecorator } = props.form
      return (
        <CSSTransition in={true} timeout={300} classNames="alert" unmountOnExit>
          <div style={{ width: '100%' }}>
            <Form onSubmit={this.handleSubmit} className="post-click-form">
              <h3 style={{ textAlign: 'center' }}>
                If you'd like, add your name and a photo
              </h3>
              <Form.Item style={{ textAlign: 'center' }}>
                {getFieldDecorator('name', {
                  rules: [
                    { required: false, message: 'Please input your name!' },
                  ],
                })(
                  <Input
                    size="large"
                    maxLength={50}
                    autoFocus={this.props.isMobile ? false : true}
                    prefix={
                      <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />
                    }
                    placeholder="Name (optional)"
                  />
                )}
              </Form.Item>
              <Form.Item>
                <Upload
                  name="photo"
                  multiple={false}
                  accept="image/png, image/jpeg"
                  customRequest={this.onPostImage}
                  onChange={() => this.setState({ isUploading: true })}
                  listType="picture"
                  fileList={this.state.fileList}
                >
                  <Button type="secondary" size="large" block={true}>
                    {this.state.isUploading ? (
                      <Icon type="loading" />
                    ) : (
                      <>
                        <Icon type="upload" />
                        <span>Add Photo (optional)</span>
                      </>
                    )}
                  </Button>
                </Upload>
              </Form.Item>
              <Form.Item style={{ textAlign: 'center' }}>
                <Button
                  style={{ minWidth: 140 }}
                  onClick={this.props.getLocation}
                >
                  {this.renderLocationInfo()}
                </Button>
              </Form.Item>
              <Form.Item style={{ textAlign: 'center' }}>
                <div className="form-button-group">
                  <Button
                    type="secondary"
                    size="large"
                    disabled={this.props.isPosting}
                    htmlType="submit"
                    className="click-submit-button"
                  >
                    Skip
                  </Button>
                  <Button
                    type="primary"
                    size="large"
                    disabled={this.props.isPosting || this.state.isUploading}
                    htmlType="submit"
                    className="click-submit-button"
                  >
                    {this.props.isPosting ? (
                      <Icon type="loading" />
                    ) : (
                      'Submit details'
                    )}
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </div>
        </CSSTransition>
      )
    }
  }

  render() {
    return this.renderFormBody(this.props)
  }
}

const mapSizesToProps = ({ width }) => ({
  isMobile: width < 480,
})

const WrappedPostClickForm = Form.create({ name: 'post_click' })(PostClickForm)
export default withSizes(mapSizesToProps)(WrappedPostClickForm)
