import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import ReactGA from 'react-ga'
import UnityButtonContainer from './components/UnityButtonContainer'
// import Sponsor from './components/Sponsor'
import Donate from './components/Donate'
import About from './components/About'
import { loadReCaptcha } from 'react-recaptcha-v3'

class App extends React.Component {
  componentDidMount() {
    loadReCaptcha('6LcyW-cUAAAAAF3831qIft7I2RCZ1KEQmNOW1l0h')
    this.initializeReactGA()
  }

  initializeReactGA = () => {
    ReactGA.initialize('UA-138039804-3')
    ReactGA.pageview('/homepage')
  }

  render() {
    return (
      <Router>
        <div>
          <Route path="/" exact component={UnityButtonContainer} />
          <Route path="/about" component={About} />
          <Route path="/donate" component={Donate} />
          {/* <Route path="/sponsor" component={Sponsor} /> */}
        </div>
      </Router>
    )
  }
}

export default App
