import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Modal, message } from 'antd'
import ReactGA from 'react-ga'
import api from '../services/api'
import geoCoderHelper from '../helpers/geoHelpers'
import WrappedPostClickForm from './Form'
import UnityButton from './UnityButton'
import SharePanel from './SharePanel'
import Footer from './Footer'
import LastCickedInfo from './LastClickedInfo'
import Map from './Map'
import { ReCaptcha } from 'react-recaptcha-v3'

export default class UnityButtonContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      hasBeenClicked: false,
      isModalVisible: false,
      isCheckingIP: false,
      isSharePanelVisible: false,
      isPosting: false,
      isFetchingClicks: true,
      isFetchingIP: true,
      isFinishedLocating: false,
      ipAddress: '',
      clickBody: {
        coordinates: [],
        name: '',
        regionName: {},
        date: '',
      },
      clicks: [],
    }
    this.unityButtonRef = React.createRef()
    this.niceClickRef = React.createRef()
    this.niceClickContainer = React.createRef()
  }

  componentDidMount = async () => {
    let clicks, IPData, ipAddress
    try {
      clicks = await api.getClickInfo()
      this.setState({ clicks, isFetchingClicks: false })
    } catch (err) {
      console.log('error loading clicks', err)
    }
    const body = {}

    api.subscribeToClickChanges((event) => {
      const { click } = event
      const currentClicks = this.state.clicks
      return this.setState({
        clicks: [click, ...currentClicks],
      })
    })

    try {
      IPData = await api.getIpAddress()
      ipAddress = IPData.query
      let clickBody = Object.assign({}, this.state.clickBody)

      body.ipAddress = ipAddress
      clickBody.regionName.locality = IPData.regionName
      clickBody.regionName.country = IPData.country
      clickBody.coordinates = [IPData.lon, IPData.lat]

      this.setState({
        clickBody,
        ipAddress,
      })
    } catch (err) {
      console.log('could not locate IP address. ', err)
    }

    try {
      // This is the old IP check, maybe bring it back with fingerprintJS
      // const hasAlreadyClicked = await this.checkForPreviousClick(
      //   IPDataPlusNavigator
      // )
      const hasAlreadyClicked = this.checkForPreviousClick()

      if (hasAlreadyClicked) {
        this.setState({ hasBeenClicked: true, isCheckingIP: false })
      }
    } catch (err) {
      console.log('error checking click: ' + err)
      this.setState({
        isCheckingIP: false,
        error: 'Error fetching data, please contact us!',
      })
      message.error('Error fetching data, please contact us!', 15)
    }

    this.setState({ isFetchingIP: false })
    this.hidePreviousClickMsg()
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.clicks &&
      prevState.clicks &&
      this.state.clicks.length !== prevState.clicks.length
    ) {
      this.setState({ isTransitioning: true }, () => {
        this.setState({ isTransitioning: false })
      })
    }
  }

  verifyCallback = (recaptchaToken) => {
    console.log('setting captcha')
    this.setState({ recaptchaToken })
    // var data = {
    //   hello: 'hi',
    //   token: recaptchaToken,
    // }

    // fetch('/send', {
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json',
    //   },
    //   method: 'post',
    //   body: JSON.stringify(data),
    // })
    //   .then((res) => {
    //     // debugger
    //     res.text()
    //     // debugger
    //   })
    //   // .then(response => response.text())
    //   // .then(text => showResult(text))
    //   .catch((error) => message.error('You are a bot?'))
  }

  onSubmit = () => {
    this.setState({ isPosting: true })
  }

  handleCancelModal = () => {
    ReactGA.event({
      category: 'User',
      action: 'Modal Cancel',
    })
    this.handleFormSubmit()
  }

  handleFormSubmit = (values) => {
    this.setState({ isPosting: true }, () => {
      let clickBody = Object.assign({}, this.state.clickBody)
      clickBody.name = values && values.name ? values.name : ''
      clickBody.image = values && values.image ? values.image : ''

      this.setState({ clickBody }, async () => {
        // if (!this.state.recaptchaToken) {
        //   this.setState({ isPosting: false })
        //   message.info('Please verify that you are a human', 8)
        // } else {
        let JWT = await api.signJWT(clickBody)
        this.postClick(JWT)
        // }
      })
    })
  }

  checkForPreviousClick = () => {
    let hasPreviouslyClicked
    if (window.localStorage.getItem('hasPreviouslyClicked')) {
      hasPreviouslyClicked = true
    }
    return hasPreviouslyClicked
  }

  getLocation = () => {
    var self = this
    if (navigator.geolocation) {
      this.setState({ isGettingGeolocation: true }, () => {
        navigator.geolocation.getCurrentPosition(this.handlePostion, function (
          error
        ) {
          if (error.code && error.code === error.PERMISSION_DENIED) {
            message.warn('Geolocation is not supported by this browser.', 12)
            self.setState({ isGettingGeolocation: false })
          }
        })
      })
    } else {
      message.warn('Geolocation is not supported by this browser.')
      this.setState({ isGettingGeolocation: false })
    }
  }

  handlePostion = async (position) => {
    this.setState({ isGettingGeolocation: false, isFinishedLocating: true })
    let regionName = await geoCoderHelper.getGeoName(position.coords)
    let clickBody = Object.assign({}, this.state.clickBody)

    if (regionName) {
      clickBody.regionName.locality = regionName.locality
      clickBody.regionName.country = regionName.country
      clickBody.coordinates = [
        position.coords.longitude,
        position.coords.latitude,
      ]
    }

    this.setState({ clickBody })
  }

  handleButtonClick = async (e) => {
    console.log('clicking')
    ReactGA.event({
      category: 'User',
      action: 'Button Click',
    })
    let hasPreviouslyClicked = await this.checkForPreviousClick()
    if (hasPreviouslyClicked) {
      this.setState({ hasBeenClicked: true })
      message.warn('Only one Unity Button click per human being')
      message.config({
        duration: 10,
        maxCount: 1,
      })
    } else {
      this.handleButtonAnimation()
      window.localStorage.setItem('hasPreviouslyClicked', 'true')
    }
  }

  hidePreviousClickMsg = () => {
    if (this.state.hasBeenClicked) {
      const niceClickContainer = this.niceClickContainer.current
      if (niceClickContainer) {
        setTimeout(() => {
          niceClickContainer.style.display = 'none'
        }, 5000)
      }
    }
    setTimeout(() => {
      const prevClickMsg = this.niceClickRef.current
      if (prevClickMsg) {
        prevClickMsg.style.transform = 'translateY(20px)'
        prevClickMsg.style.opacity = '0'
      }
    }, 4000)
  }

  handleButtonAnimation = () => {
    const unityButton =
      this.unityButtonRef.current && this.unityButtonRef.current.children[0]
    const unityButtonBackground =
      this.unityButtonRef.current && this.unityButtonRef.current.children[1]
    unityButton.style.transform = 'scale(20)'
    unityButton.style.position = 'absolute'
    unityButton.style.opacity = '4'
    setTimeout(() => {
      unityButtonBackground.style.transform = 'translate(-50%, -50%) scale(20)'
      unityButtonBackground.style.opacity = '1'
      unityButtonBackground.style.position = 'absolute'
      unityButtonBackground.style.opacity = '4'
      unityButtonBackground.style.height = '100vh'
      unityButtonBackground.style.width = '100vw'
    }, 300)
    setTimeout(() => {
      unityButton.style.opacity = '0'
    }, 400)
    setTimeout(() => {
      this.setState({ isModalVisible: true })
    }, 1400)
  }

  postClick = async (JWT) => {
    const body = {}
    const { clickBody } = this.state

    body.coordinates = clickBody.coordinates
    body.name = clickBody.name
    body.regionName = clickBody.regionName
    body.image = clickBody.image
    body.date = new Date().toUTCString()
    console.log('posting')

    api
      .postClick(body, JWT)
      .then((res) => {
        window.scrollTo(0, 0)
        this.setState({ isModalVisible: false })
        if (res && res.data === 'You already have used this IP!') {
          message.warn('Only one Unity Button click per human being', 10)
        } else {
          this.setState(
            {
              hasBeenClicked: true,
              isPosting: false,
            },
            () => {
              this.hidePreviousClickMsg()
              setTimeout((_) => {
                this.setState({ isSharePanelVisible: true })
              }, 3000)
            }
          )
        }
      })
      .catch((err) => {
        console.log('error posting click: ' + err)
        message.error(
          'Error posting your click! Please contact unitybutton@gmail.com.',
          10
        )
        const unityButton = this.unityButtonRef.current.children[0]
        const unityButtonBackground = this.unityButtonRef.current.children[1]

        if (unityButton && unityButton.style) {
          unityButton.style.display = 'none'
        }
        if (unityButtonBackground && unityButtonBackground.style) {
          unityButtonBackground.style.display = 'none'
        }
        this.setState({
          hasBeenClicked: false,
          error: 'Error posting click',
          isModalVisible: false,
          isPosting: false,
        })
      })
  }

  toggleSharePanel = () =>
    this.setState({ isSharePanelVisible: !this.state.isSharePanelVisible })

  render() {
    return (
      <div className="ub-wrapper">
        <div className="background-overlay"></div>
        <Link to="/" className="ub__logo">
          Connecting humanity - one click at a time.
        </Link>
        {/* <div className="maintenance-header">
          <h1 style={{ color: 'white' }}>
            The Unity Button is currently undergoing maintenance.
          </h1>
          <h2 style={{ color: 'white' }}>Clicking has been paused for now.</h2>
        </div> */}
        <ReCaptcha
          sitekey="6LcyW-cUAAAAAF3831qIft7I2RCZ1KEQmNOW1l0h"
          action="button_click"
          verifyCallback={this.verifyCallback}
        />

        {this.state.isSharePanelVisible && (
          <SharePanel
            isSharePanelVisible={this.state.isSharePanelVisible}
            toggleSharePanel={this.toggleSharePanel}
          />
        )}

        {this.state.error && this.state.error === 'Error posting click' ? (
          ''
        ) : (
          <UnityButton
            ref={[
              this.niceClickRef,
              this.unityButtonRef,
              this.niceClickContainer,
            ]}
            isFetchingIP={this.state.isFetchingIP}
            hasBeenClicked={this.state.hasBeenClicked}
            handleButtonClick={this.handleButtonClick}
          />
        )}
        <Map clicks={this.state.clicks} />
        <LastCickedInfo
          isFetchingClicks={this.state.isFetchingClicks}
          isTransitioning={this.state.isTransitioning}
          lastClickData={this.state.clicks ? this.state.clicks[0] : []}
          clicks={this.state.clicks}
        />

        <Modal
          title={null}
          footer={null}
          height="100vh"
          style={{ top: 0 }}
          width="100vw"
          className="post-click-modal"
          mask={false}
          closable={!this.state.isPosting}
          visible={this.state.isModalVisible}
          onClose={() => this.handleFormSubmit(null)}
          onCancel={this.handleCancelModal}
        >
          <WrappedPostClickForm
            isPosting={this.state.isPosting}
            isFinishedLocating={this.state.isFinishedLocating}
            onSubmit={this.onSubmit}
            isGettingGeolocation={this.state.isGettingGeolocation}
            getLocation={this.getLocation}
            handleFormSubmit={this.handleFormSubmit}
          />
        </Modal>
        <Footer toggleSharePanel={this.toggleSharePanel}></Footer>
      </div>
    )
  }
}
