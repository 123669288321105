import React, { useState } from 'react'
import { Tooltip, Drawer, Button, Icon } from 'antd'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { TwitterFollowButton } from 'react-twitter-embed'

import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  RedditShareButton,
  RedditIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from 'react-share'

const SharePanel = (props) => {
  const [isCopied, copyToClipBoard] = useState(0)

  const shareUrl = 'https://www.unitybutton.com/'
  const shareText =
    "Just got my Unity Button click in! Unity Button: We're all in this together."
  return (
    <Drawer
      title="Today's task: Unite humanity 🌍"
      placement="top"
      closable={true}
      height={'400px'}
      onClose={props.toggleSharePanel}
      visible={props.isSharePanelVisible}
    >
      <p>Every share helps!</p>
      <div className="SharePanel__buttons">
        <TwitterShareButton
          url={shareUrl}
          title={shareText}
          hashtags={[
            'unitybutton',
            'IClickedTheButton',
            'ClickForCOVID-19',
            'WeAreAllInThisTogether',
          ]}
        >
          <Tooltip title="Twitter">
            <TwitterIcon size={42} round />
          </Tooltip>
        </TwitterShareButton>
        <RedditShareButton
          url={shareUrl}
          title={shareText}
          windowWidth={660}
          windowHeight={460}
        >
          <Tooltip title="Reddit">
            <RedditIcon size={42} round />
          </Tooltip>
        </RedditShareButton>
        <FacebookShareButton
          url={shareUrl}
          quote={shareText}
          hashtag="#IClickedTheButton"
        >
          <Tooltip title="Facebook">
            <FacebookIcon size={42} round />
          </Tooltip>
        </FacebookShareButton>
        <WhatsappShareButton url={shareUrl} title={shareText} separator=":: ">
          <Tooltip title="WhatsApp">
            <WhatsappIcon size={42} round />
          </Tooltip>
        </WhatsappShareButton>
        <LinkedinShareButton url={shareUrl} title={shareText} separator=":: ">
          <Tooltip title="LinkedIn">
            <LinkedinIcon size={42} round />
          </Tooltip>
        </LinkedinShareButton>
        <EmailShareButton
          subject={shareText}
          body={`I just clicked the Unity Button. Here's where you can click it, too: ${shareUrl}`}
        >
          <Tooltip title="Email">
            <EmailIcon size={42} round />
          </Tooltip>
        </EmailShareButton>
      </div>
      <div style={{ marginTop: 16 }}>
        <p>
          Want to share on Instagram or SnapChat? Copy the url and add it to
          your story:
        </p>
        <CopyToClipboard
          text={shareUrl}
          onCopy={() => copyToClipBoard({ copied: true })}
        >
          <Button>
            {isCopied ? (
              <>
                <Icon type="check-circle" />
                <span> Copied!</span>
              </>
            ) : (
              'Copy unitybutton.com to clipboard'
            )}{' '}
          </Button>
        </CopyToClipboard>
        <div className="SharePanel__follow" style={{ margin: '24px auto 0' }}>
          <h3>Join the movement.</h3>
          <div style={{ height: 25 }}>
            <TwitterFollowButton screenName={'UnityButton'} />
          </div>
          <a
            className="SharePanel__follow_IG"
            style={{ borderBottom: '1px solid white' }}
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.instagram.com/theunitybutton/"
          >
            Follow us on Instagram
          </a>
        </div>
      </div>
    </Drawer>
  )
}

export default SharePanel
