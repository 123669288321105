import axios from 'axios'

let apiKey = process.env.REACT_APP_GOOGLE_KEY

export default {
  getGeoName(coordinates) {
    var self = this
    const { latitude, longitude } = coordinates
    return axios
      .get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`
      )
      .then(res => {
        if (
          res.data &&
          res.data.status === 'OK' &&
          res.data.results &&
          res.data.results.length
        ) {
          return self.getCountry(res.data.results)
        }
      })
      .catch(err => console.log(err))
  },

  getCountry(results) {
    var self = this
    var locationInfo = {}
    for (var i = 0; i < results[0].address_components.length; i++) {
      var shortname = results[0].address_components[i].short_name
      var longname = results[0].address_components[i].long_name
      var type = results[0].address_components[i].types
      if (type.indexOf('country') !== -1) {
        if (!self.isNullOrWhitespace(longname)) {
          locationInfo.country = longname
        } else {
          locationInfo.country = shortname
        }
      }
      if (type.indexOf('locality') !== -1) {
        if (!self.isNullOrWhitespace(longname)) {
          locationInfo.locality = longname
        } else {
          locationInfo.locality = shortname
        }
      }
    }

    return locationInfo
  },

  isNullOrWhitespace(text) {
    if (text === null) {
      return true
    }
    return text.replace(/\s/gi, '').length < 1
  },
}
