import React from 'react'
import { Link } from 'react-router-dom'
import { Icon } from 'antd'

export default class Donate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <div className="info-page">
        <Link className="back-button" to="/">
          <Icon type="left" />
          &nbsp;Home
        </Link>
        <h1>Here are our costs:</h1>
      </div>
    )
  }
}
