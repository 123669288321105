import React from 'react'
import { Collapse, Icon, Modal } from 'antd'
import { Link } from 'react-router-dom'
import ReactGA from 'react-ga'
import { TwitterFollowButton } from 'react-twitter-embed'

const { Panel } = Collapse

const textIsThisReal = (
  <p style={{ paddingLeft: 24 }}>
    It's as real as buttons on the internet can get. Each of the "Clicks" is
    meant to evoke a sense of solidarity during the COVID-19 pandemic. So, yes,
    it's for real.
  </p>
)
const textData = (
  <p style={{ paddingLeft: 24 }}>
    While it is necessary to save data to our secure database (mongoDB), in
    order to create the click counter, map, and "last-clicked" information, we
    are not sharing nor planning on sharing any data with any other service,
    agency, or company.
  </p>
)
const textOneClick = (
  <p style={{ paddingLeft: 24 }}>
    There are ways to click more than once. However, the point is just to get
    your click in. If you really need to have multiple clicks on one device,
    (i.e. you are a teacher and every kid in class wants to click the button
    from your school computer), then we can help - contact us!
  </p>
)
const textMap = (
  <p style={{ paddingLeft: 24 }}>
    The map gives a sense of connectedness. We use publicly available IP
    addresses to get a rough idea of where people are clicking from. This is
    intentional, so that there is not too much (creepy-level) accuracy for any
    click location.
  </p>
)

export default class About extends React.Component {
  constructor(props) {
    super(props)
    this.state = { visible: false }
  }

  componentDidMount() {
    ReactGA.pageview('/about')
  }

  handlePanelView = (panelName) => {
    ReactGA.event({
      category: 'About Panel',
      action: `${panelName} Click`,
    })
  }

  handleLinkCLick = (linkDestination) => {
    ReactGA.event({
      category: 'About Panel',
      action: `${linkDestination} Click`,
    })
  }

  showModal = () => {
    this.handleLinkCLick('Privacy Polict')
    this.setState({
      visible: true,
    })
  }

  handleOk = (e) => {
    console.log(e)
    this.setState({
      visible: false,
    })
  }

  handleCancel = (e) => {
    console.log(e)
    this.setState({
      visible: false,
    })
  }

  render() {
    return (
      <div className="info-page">
        <Link className="back-button" to="/">
          <Icon type="left" />
          &nbsp;Home
        </Link>
        <h1 className="color-white">What is the Unity Button?</h1>
        <p className="color-white info-page__details">
          It's just a button. We believe that during these uncertain times, it
          would be nice to have everyone experience a shared action. This is a
          time that calls for unity.
        </p>
        <div style={{ margin: '24px auto' }}>
          <h3 className="color-white">Join the movement.</h3>
          <TwitterFollowButton screenName={'UnityButton'} />
          <a
            style={{ borderBottom: '1px solid white' }}
            className="color-white"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.instagram.com/theunitybutton/"
          >
            Follow on Instagram
          </a>
        </div>
        <div className="container">
          <p style={{ marginTop: 32 }} className="color-white">
            FAQ:
          </p>
          <Collapse
            accordion={true}
            onChange={this.handlePanelView}
            bordered={false}
          >
            <Panel header="Is this for real?" key="isForReal">
              {textIsThisReal}
            </Panel>
            <Panel
              header="Are you sharing any of this data?"
              key="isSharingData"
            >
              {textData}
            </Panel>
            <Panel header="Can I click more than once?" key="moreThanOneClick">
              {textOneClick}
            </Panel>
            <Panel header="Why the map?" key="whyMap">
              {textMap}
            </Panel>
            <Panel header="Who built this?" key="whoBuiltThis">
              <div>
                <p style={{ paddingLeft: 24 }}>
                  Design and code by Gabe. <br />
                  Co-founder of the bicycle touring app:{' '}
                  <a
                    onClick={() => this.handleLinkCLick('ventur')}
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.ventur.co/"
                  >
                    www.ventur.co
                  </a>
                  <br />
                  Twitter:&nbsp;
                  <a
                    onClick={() => this.handleLinkCLick('twitter - gabe')}
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://twitter.com/gabeleoni"
                  >
                    @gabeleoni
                  </a>
                  .
                </p>
                <p>
                  Idea and support by Willing (LinkedIn:&nbsp;
                  <a
                    onClick={() => this.handleLinkCLick('linkedin - will')}
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.linkedin.com/in/willing-howard/"
                  >
                    Will's profile
                  </a>
                  ) &amp; Ryland (Twitter:&nbsp;
                  <a
                    onClick={() => this.handleLinkCLick('twitter - ryland')}
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://twitter.com/RylandKing"
                  >
                    @rylandking
                  </a>
                  ).
                </p>
              </div>
            </Panel>
          </Collapse>
          <p style={{ marginTop: 32 }} className="color-white">
            Contact:{' '}
          </p>
          <a className="color-white" href="mailto:theunitybutton@gmail.com">
            theunitybutton@gmail.com
          </a>
        </div>
        <p style={{ marginTop: 24, fontSize: 12 }}>
          <Icon type="copyright" /> unitybutton.com is a Rind, LLC project |{' '}
          <a
            onClick={() => this.showModal()}
            className="color-white"
            href="#privacy"
          >
            privacy policy
          </a>
        </p>
        <Modal
          title="Privacy Policy for Unity Button, by Rind, LLC"
          visible={this.state.visible}
          style={{ maxWidth: 820 }}
          width={'calc(100% - 20px)'}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <h1>Privacy Policy for Rind, LLC</h1>

          <p>
            At unitybutton.com, accessible from www.unitybutton.com, one of our
            main priorities is the privacy of our visitors. This Privacy Policy
            document contains types of information that is collected and
            recorded by unitybutton.com and how we use it.
          </p>

          <p>
            If you have additional questions or require more information about
            our Privacy Policy, do not hesitate to contact us.
          </p>

          <p>
            This Privacy Policy applies only to our online activities and is
            valid for visitors to our website with regards to the information
            that they shared and/or collect in unitybutton.com. This policy is
            not applicable to any information collected offline or via channels
            other than this website.
          </p>

          <h2>Consent</h2>

          <p>
            By using our website, you hereby consent to our Privacy Policy and
            agree to its terms.
          </p>

          <h2>Information we collect</h2>

          <p>
            The personal information that you are asked to provide, and the
            reasons why you are asked to provide it, will be made clear to you
            at the point we ask you to provide your personal information.
          </p>
          <p>
            If you contact us directly, we may receive additional information
            about you such as your name, email address, phone number, the
            contents of the message and/or attachments you may send us, and any
            other information you may choose to provide.
          </p>
          <p>
            When you register for an Account, we may ask for your contact
            information, including items such as name, company name, address,
            email address, and telephone number.
          </p>

          <h2>How we use your information</h2>

          <p>
            We use the information we collect in various ways, including to:
          </p>

          <ul>
            <li>Provide, operate, and maintain our webste</li>
            <li>Improve, personalize, and expand our webste</li>
            <li>Understand and analyze how you use our webste</li>
            <li>Develop new products, services, features, and functionality</li>
            <li>
              Communicate with you, either directly or through one of our
              partners, including for customer service, to provide you with
              updates and other information relating to the webste, and for
              marketing and promotional purposes
            </li>
            <li>Send you emails</li>
            <li>Find and prevent fraud</li>
          </ul>

          <h2>Log Files</h2>

          <p>
            unitybutton.com follows a standard procedure of using log files.
            These files log visitors when they visit websites. All hosting
            companies do this and a part of hosting services' analytics. The
            information collected by log files include internet protocol (IP)
            addresses, browser type, Internet Service Provider (ISP), date and
            time stamp, referring/exit pages, and possibly the number of clicks.
            These are not linked to any information that is personally
            identifiable. The purpose of the information is for analyzing
            trends, administering the site, tracking users' movement on the
            website, and gathering demographic information. Our Privacy Policy
            was created with the help of the{' '}
            <a href="https://www.privacypolicygenerator.info">
              Privacy Policy Generator
            </a>{' '}
            and the{' '}
            <a href="https://www.privacypolicytemplate.net/">
              Privacy Policy Template
            </a>
            .
          </p>

          <h2>Cookies and Web Beacons</h2>

          <p>
            Like any other website, unitybutton.com uses 'cookies'. These
            cookies are used to store information including visitors'
            preferences, and the pages on the website that the visitor accessed
            or visited. The information is used to optimize the users'
            experience by customizing our web page content based on visitors'
            browser type and/or other information.
          </p>

          <p>
            For more general information on cookies, please read{' '}
            <a href="https://www.cookieconsent.com/what-are-cookies/">
              "What Are Cookies"
            </a>
            .
          </p>

          <h2>Advertising Partners Privacy Policies</h2>

          <p>
            You may consult this list to find the Privacy Policy for each of the
            advertising partners of unitybutton.com.
          </p>

          <p>
            Third-party ad servers or ad networks uses technologies like
            cookies, JavaScript, or Web Beacons that are used in their
            respective advertisements and links that appear on unitybutton.com,
            which are sent directly to users' browser. They automatically
            receive your IP address when this occurs. These technologies are
            used to measure the effectiveness of their advertising campaigns
            and/or to personalize the advertising content that you see on
            websites that you visit.
          </p>

          <p>
            Note that unitybutton.com has no access to or control over these
            cookies that are used by third-party advertisers.
          </p>

          <h2>Third Party Privacy Policies</h2>

          <p>
            unitybutton.com's Privacy Policy does not apply to other advertisers
            or websites. Thus, we are advising you to consult the respective
            Privacy Policies of these third-party ad servers for more detailed
            information. It may include their practices and instructions about
            how to opt-out of certain options. You may find a complete list of
            these Privacy Policies and their links here: Privacy Policy Links.
          </p>

          <p>
            You can choose to disable cookies through your individual browser
            options. To know more detailed information about cookie management
            with specific web browsers, it can be found at the browsers'
            respective websites. What Are Cookies?
          </p>

          <h2>CCPA Privacy Rights (Do Not Sell My Personal Information)</h2>

          <p>
            Under the CCPA, among other rights, California consumers have the
            right to:
          </p>
          <p>
            Request that a business that collects a consumer's personal data
            disclose the categories and specific pieces of personal data that a
            business has collected about consumers.
          </p>
          <p>
            Request that a business delete any personal data about the consumer
            that a business has collected.
          </p>
          <p>
            Request that a business that sells a consumer's personal data, not
            sell the consumer's personal data.
          </p>
          <p>
            If you make a request, we have one month to respond to you. If you
            would like to exercise any of these rights, please contact us.
          </p>

          <h2>GDPR Data Protection Rights</h2>

          <p>
            We would like to make sure you are fully aware of all of your data
            protection rights. Every user is entitled to the following:
          </p>
          <p>
            The right to access – You have the right to request copies of your
            personal data. We may charge you a small fee for this service.
          </p>
          <p>
            The right to rectification – You have the right to request that we
            correct any information you believe is inaccurate. You also have the
            right to request that we complete the information you believe is
            incomplete.
          </p>
          <p>
            The right to erasure – You have the right to request that we erase
            your personal data, under certain conditions.
          </p>
          <p>
            The right to restrict processing – You have the right to request
            that we restrict the processing of your personal data, under certain
            conditions.
          </p>
          <p>
            The right to object to processing – You have the right to object to
            our processing of your personal data, under certain conditions.
          </p>
          <p>
            The right to data portability – You have the right to request that
            we transfer the data that we have collected to another organization,
            or directly to you, under certain conditions.
          </p>
          <p>
            If you make a request, we have one month to respond to you. If you
            would like to exercise any of these rights, please contact us.
          </p>

          <h2>Children's Information</h2>

          <p>
            Another part of our priority is adding protection for children while
            using the internet. We encourage parents and guardians to observe,
            participate in, and/or monitor and guide their online activity.
          </p>

          <p>
            unitybutton.com does not knowingly collect any Personal Identifiable
            Information from children under the age of 13. If you think that
            your child provided this kind of information on our website, we
            strongly encourage you to contact us immediately and we will do our
            best efforts to promptly remove such information from our records.
          </p>
        </Modal>
      </div>
    )
  }
}
