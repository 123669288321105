import React from 'react'
import ReactMapboxGl, { Layer, Feature } from 'react-mapbox-gl'
import withSizes from 'react-sizes'

const token = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN
var Map = ReactMapboxGl({
  accessToken: token,
})

const flyToOptions = {
  speed: 0.8,
}

class WrappedMap extends React.Component {
  constructor() {
    super()
    this.state = {
      center: [8.0926, 31.7917],
      isVisible: false,
    }

    this.markerClick = this.markerClick.bind(this)
    this.renderCoords = this.renderCoords.bind(this)
  }

  componentDidMount() {
    setTimeout((_) => {
      // Adds delay to the map fade in
      this.setState({ isVisible: true })
    }, 2500)
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.clicks &&
      prevProps.clicks &&
      this.props.clicks.length !== prevProps.clicks.length
    ) {
      let center

      if (
        this.props.clicks[0].coordinates &&
        this.props.clicks[0].coordinates.length
      ) {
        center = this.props.clicks[0].coordinates
      } else {
        return // user did not allow location
      }
      this.setState({
        center,
      })
    }
  }

  markerClick(coordinates) {
    console.log('coords: ' + coordinates)
  }

  renderCoords(coordinates) {
    if (coordinates && coordinates[0] && coordinates[1]) {
      return coordinates
    } else {
      return [-122.4194, 37.7749]
    }
  }

  render() {
    const { clicks } = this.props

    if (clicks) {
      return (
        <div className="ub__map">
          <div
            className="ub__map-wrapper"
            // style={{ opacity: `${this.state.isVisible ? 1 : 0}` }}
          >
            <Map
              onRender={this.onRenderMap}
              // eslint-disable-next-line
              style="mapbox://styles/gabeleoni/cjd7nj8tl87o72trlvrzd6i4p"
              center={this.state.center}
              containerStyle={{
                width: '100vw',
                height: `${
                  this.props.isMobile
                    ? 'calc(100vh - 210px)'
                    : 'calc(100vh - 95px)'
                }`,
              }}
              zoom={[1.7]}
              flyToOptions={flyToOptions}
            >
              <Layer type="heatmap" paint={layerPaint} id="marker">
                {clicks.map((click, idx) => {
                  return (
                    <Feature
                      onClick={() => this.markerClick(click.coordinates)}
                      key={idx}
                      coordinates={this.renderCoords(click.coordinates)}
                    />
                  )
                })}
              </Layer>
              )
            </Map>
          </div>
        </div>
      )
    } else return <div className="ub__map"></div>
  }
}
const layerPaint = {
  'heatmap-weight': {
    property: 'priceIndicator',
    type: 'exponential',
    stops: [
      [0, 0],
      [5, 2],
    ],
  },
  // Increase the heatmap color weight weight by zoom level
  // heatmap-ntensity is a multiplier on top of heatmap-weight
  'heatmap-intensity': {
    stops: [
      [0, 0],
      [5, 1.2],
    ],
  },
  // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
  // Begin color ramp at 0-stop with a 0-transparancy color
  // to create a blur-like effect.
  'heatmap-color': [
    'interpolate',
    ['linear'],
    ['heatmap-density'],
    0,
    'rgba(33,102,172,0)',
    0.25,
    'rgb(103,169,207)',
    0.5,
    'rgb(209,229,240)',
    0.8,
    'rgb(253,219,199)',
    1,
    'rgb(239,138,98)',
    2,
    'rgb(178,24,43)',
  ],
}

const mapSizesToProps = ({ width }) => ({
  isMobile: width < 480,
})

export default withSizes(mapSizesToProps)(WrappedMap)
