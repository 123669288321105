import io from 'socket.io-client'
import axios from 'axios'
import jwt from 'jsonwebtoken'
import imageHelpers from '../helpers/imageHelpers'
import geoHelpers from '../helpers/geoHelpers'

const baseUrl =
  process.env.NODE_ENV === 'development' ? 'http://localhost:5000' : ''
const googleKey = process.env.REACT_APP_GOOGLE_KEY
const IPApiKey =
  process.env.NODE_ENV === 'development'
    ? 'sK7k7py722zCdS8'
    : process.env.REACT_APP_IPAPI_KEY
const createSocket = () => {
  const socket = io(baseUrl)
  return socket
}

export default {
  signJWT(data) {
    let encriptedJWT = jwt.sign(
      {
        data,
      },
      process.env.REACT_APP_JWT_SECRET,
      { expiresIn: 15 }
    )
    return encriptedJWT
  },

  async postImage({ file }) {
    try {
      const orientedImg = await imageHelpers.orientImage(file)
      const resizedImg = await imageHelpers.resizeImage({
        orientedImg,
        maxSize: 500,
      })
      const formData = new FormData()

      formData.append('photo', resizedImg)
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      }
      return axios.post(`${baseUrl}/api/upload`, formData, config)
    } catch (err) {
      throw new Error(err)
    }
  },

  getIpAddress() {
    const url = `https://pro.ip-api.com/json?key=${IPApiKey}`
    return axios
      .get(url)
      .then((res) => {
        return res.data
      })
      .catch((err) => {
        console.log('getIpAddress: ', err)
        throw new Error(err)
      })
  },

  postCaptcha(body) {
    return axios
      .post(`${baseUrl}/api/send`, body)
      .then((res) => {
        return res
      })
      .catch((err) => {
        console.log('captcha: ', err)
        throw new Error(err)
      })
  },

  getGeoName(coordinates) {
    const { latitude, longitude } = coordinates
    return axios
      .get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${googleKey}`
      )
      .then((res) => {
        if (
          res.data &&
          res.data.status === 'OK' &&
          res.data.results &&
          res.data.results.length
        ) {
          return geoHelpers.getCountry(res.data.results)
        }
      })
      .catch((err) => {
        console.log(err)
        throw new Error(err)
      })
  },

  checkClick(body) {
    return axios
      .post(`${baseUrl}/api/clicks-check`, body)
      .then((res) => {
        return res
      })
      .catch((err) => {
        console.log('checkClick: ', err)
        throw new Error(err)
      })
  },

  postClick(body, JWT) {
    return axios({
      method: 'POST',
      url: `${baseUrl}/api/clicks`,
      headers: { Authorization: `Bearer ${JWT}` },
      data: body,
    })
      .then((res) => {
        return res
      })
      .catch((err) => {
        console.log('postClick: ', err)
        throw new Error(err)
      })
  },

  getClickInfo() {
    return axios
      .get(`${baseUrl}/api/clicks/`)

      .then((res) => {
        return res.data.data
      })
      .catch((err) => {
        console.log('getClickInfo: ', err)
        throw new Error(err)
      })
  },

  subscribeToClickChanges(cb) {
    createSocket().on('click changeEvent', cb)
  },

  polar(domainArray) {
    let hashMap = {}
    let splitDomain
    let newArr = []
    let newJoin

    for (let domain of domainArray) {
      splitDomain = domain.split('.')
      newArr = []
      newJoin = ''

      while (splitDomain.length > 0) {
        newArr.unshift(splitDomain.pop().toString())
        newJoin = newArr.join('.').toString()
        if (hashMap[newJoin] === undefined) {
          hashMap[newJoin] = 1
        } else {
          hashMap[newJoin] = hashMap[newJoin] + 1
        }
      }
    }

    return hashMap
  },
}
